import React from 'react'

const LoadingScreen = () => {
  return (
    <div className='flex justify-center items-center z-[10001] w-full h-full bg-white absolute top-0 left-0'>
        Loading...
    </div>
  )
}

export default LoadingScreen