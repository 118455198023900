import {
  ViewerApp,
  AssetManagerPlugin,
  addBasePlugins,
  AssetManagerBasicPopupPlugin,
  VariationConfiguratorPlugin,
  GroundPlugin,
  CameraViewPlugin,
  LoadingScreenPlugin,
  InteractionPromptPlugin
} from "webgi";

class ThreeDViewer {
  constructor() {
    this.viewer = null;
    this.manager = null;
    this.config = null;
    this.controls = null;
    this.options = null;
  }

  async setupViewer(id) {
    // Initialize the viewer
    const viewer = new ViewerApp({
      canvas: document.getElementById(id)
    });
    // viewer.renderer.displayCanvasScaling = Math.min(1, window.devicePixelRatio);
    const config = await viewer.addPlugin(VariationConfiguratorPlugin);
    await config.importPath("config.json");
    await viewer.addPlugin(AssetManagerBasicPopupPlugin);
    // const manager = await viewer.addPlugin(AssetManagerPlugin);
    // await viewer.addPlugin(AssetManagerBasicPopupPlugin);
    // await viewer.addPlugin(LoadingScreenPlugin);
    // const loader = viewer.getPlugin(LoadingScreenPlugin)
    // loader.showFileNames = false
    

    await addBasePlugins(viewer);
    viewer.renderer.refreshPipeline();
    // viewer.renderEnabled = true;

   

    await viewer.load("./assets/model/engagementring-configurator.glb")
    await viewer.setEnvironmentMap( "./assets/lightmap-r-2.hdr");
    await config.applyVariation(config.variations.objects[0], 0, "objects");
    await config.applyVariation(config.variations.objects[1], 0, "objects");
    // config.applyVariation(config.variations.objects[2], 0, "objects");
    await config.applyVariation(config.variations.materials[3], 0, "materials");
    await config.applyVariation(config.variations.materials[2], 1, "materials");
    
    // Bake shadows once after model loaded and enable rendering
    const ground = viewer.getPlugin(GroundPlugin);

    //stop auto ground shadows baking and disable rendering
    ground.autoBakeShadows = false;
    ground.bakeShadows();
    
    const options = viewer.scene.activeCamera.getCameraOptions();

    options.zoom = 0.3;
    viewer.scene.activeCamera.setCameraOptions(options);
    // viewer.scene.isRootScene = false
    // viewer.scene.backgroundColor = {
    //   b: 10,
    //   g:10,
    //   isColor:true,
    //   r:10
    // }
    const controls = viewer.scene.activeCamera.controls;
    //camera views
    controls.autoRotate = false;
    controls.autoRotateSpeed = 0;
    controls.enableDamping = true;
    // controls.rotateSpeed = 2.0;
    controls.enableZoom = true;
    const interaction = viewer.getPlugin(InteractionPromptPlugin);
    interaction.autoStartOnObjectLoad=false
    console.log(interaction)
    this.config = config;
    this.viewer = viewer;
    this.controls = controls;
    // this.manager = manager;
    this.options = options
  }

  applyObjectVariation(category, index, type) {
    if (this.config) {
      const cat = this.config.variations.objects.find((cat) => {
        return cat.name === category
      });
      this.config.applyVariation(cat, index, type);
    }
  }

  async applyMaterialVariation(category, index) {
    if (this.config) {
      const cat = this.config.variations.materials.find((cat) => {
        return cat.name === category
      });
      // this.config.baseUrl='./assets/'
      this.config.applyVariation(cat, index, "materials");
    }
  }

  animateToView(index) {
    console.log('')
    const cameraView = this.viewer.getPlugin(CameraViewPlugin);
    cameraView.animateToView(cameraView.camViews[index], 1000);
  }

  handleZoomInZoomOut(value) {
    if (value) {
      //zoom in
      this.options.zoom = 0.4;
    } else {
      //zoom out
      this.options.zoom = 0.3;
    }
    this.viewer.scene.activeCamera.setCameraOptions(this.options);
  }

  handleAutorotate(value) {
    if (value) {
      //rotation on
      this.controls.autoRotate = true
    } else {
      //rotation off
      this.controls.autoRotate = false
    }
  }

  async setRingOnModelHand(){
    this.createRingCanvas()
    const bg = await this.viewer.getManager().importer.importSinglePath('./assets/images/2.jpg');
    // let bg = await manager.importer.importSinglePath(), { processImported: true });
    // bg.encoding = sRGBEncoding;
    this.viewer.scene.setBackground(bg);
    this.options.zoom = 0.05;
    this.viewer.scene.activeCamera.setCameraOptions(this.options);
    this.viewer.scene.activeCamera.position.set(0, 0, 0);
    this.viewer.scene.activeCamera.positionUpdated();
    // this.viewer.scene.activeCamera.target.set(0, -3, 0);
    // this.viewer.scene.activeCamera.targetUpdated();
  }

  createRingCanvas(){
    const ringCanvas =document.getElementById('ring-canvas')
    const context=ringCanvas.getContext('2d')
    const drawnCanavas = document.getElementById('webgi-canvas')
    context?.drawImage(
      drawnCanavas,
      0,
      0,
      drawnCanavas.width,
      drawnCanavas.height,
      0,
      0,
      ringCanvas.width,
      ringCanvas.height
  );
  }
}

const threeDViewer = new ThreeDViewer()
export {
  threeDViewer as ThreeDViewer
}






    // const calibrationSettings = localStorage.getItem('calibrationSettings') && JSON.parse(localStorage.getItem('calibrationSettings'));
    // let defaultSettings = {}
    // if (calibrationSettings && Object.keys(calibrationSettings).length > 0) {
    //   defaultSettings['basket'] = calibrationSettings.basket
    //   defaultSettings['ring'] = calibrationSettings.ring
    //   defaultSettings['diamond'] = calibrationSettings.diamond
    //   defaultSettings['metal'] = calibrationSettings.metal

    // } else {
    //   defaultSettings['basket'] = 0
    //   defaultSettings['ring'] = 0
    //   defaultSettings['diamond'] = 0
    //   defaultSettings['metal'] = 1

    // }
    // config.applyVariation(config.variations.objects[0], defaultSettings['basket'], "objects");
    // config.applyVariation(config.variations.objects[1], defaultSettings['ring'], "objects");
    // // config.applyVariation(config.variations.objects[2], 0, "objects");
    // config.applyVariation(config.variations.materials[3], defaultSettings['diamond'], "materials");
    // config.applyVariation(config.variations.materials[2], defaultSettings['metal'], "materials");