import React from "react";
import { ThreeDViewer } from "../Services/Viewer";

export default function Step(props) {
    return (
        <div className={"stepBlock" + (props.selected ? " selected" : "")}>
            <div className="circleWrapper" onClick={() => {
                // if(props.index==3){
                //     ThreeDViewer.setRingOnModelHand(props.canvasId)
                // }
                props.updateStep(props.index + 1)
            }}>
                <div className="circle">{props.index + 1}</div>
            </div>
            <span className="text-[12px]">{props.label}</span>
        </div>
    )
}