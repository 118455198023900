import axios from "axios"

class ApiService {

    getCapturedImageUrl = async (image) => {
        try {
            const url = `https://m.mirrar.com/api/v1/image/base64/upload`
            const response = await axios.post(url, image)
            return response
        } catch (error) {
            console.log(error)
        }
    }
}

const apiService = new ApiService()
export { apiService as ApiService }