import { React, createContext, useState, useEffect } from 'react';

const AppContext = createContext();

const AppContextProvider = ({ children }) => {
    const metal = [
        {
            src: './assets/images/Metals/rose.png',
            name: 'Rose Gold'
        },
        {
            src: './assets/images/Metals/White.png',
            name: 'White Gold'
        },
        {
            src: './assets/images/Metals/Yellow.png',
            name: 'Yellow Gold'
        }
    ]
    const carat = [
        {
            src: './assets/images/Carat/14.png',
            name: '14K'
        },
        {
            src: './assets/images/Carat/18.png',
            name: '18K'
        },
        {
            src: './assets/images/Carat/22.png',
            name: '22K'
        },
    ]
    const crown = [
        {
            src: './assets/images/Crown/cushionhalo.png',
            name: 'Cushion Halo'
        },
        {
            src: './assets/images/Crown/pearhalo.png',
            name: 'Pear Halo'
        },
        {
            src: './assets/images/Crown/solitaire.png',
            name: 'Solitair'
        },
        {
            src: './assets/images/Crown/none.png',
            name: 'Empty'
        },
        
    ]
    const rings = [
        {
            src: './assets/images/Bands/alliance.png',
            name: 'Alliance'
        },
        {
            src: './assets/images/Bands/baguette.png',
            name: 'Baguette'
        },
        {
            src: './assets/images/Bands/pave.png',
            name: 'Pave'
        },
        {
            src: './assets/images/Bands/sidepave.png',
            name: 'Pave Side'
        },
        {
            src: './assets/images/Bands/plain.png',
            name: 'Soli'
        },
    ]

    const label = [
        {
            'src': './assets/images/Diamonds/ruby.png',
            name: 'Gemstone'
        }
    ]
    const gemstone = [
        {
            'src': './assets/images/Diamonds/ruby.png',
            colorName: 'Red'
        },
        {
            'src': './assets/images/Diamonds/emerald.png',
            colorName: 'Green'
        },
        {
            src: './assets/images/Diamonds/sapphire.png',
            colorName: 'Blue'
        }
    ]

    const diamond = [
        {
            'src': './assets/images/Diamonds/diamond.png',
            colorName: 'White'
        },
        {
            'src': './assets/images/Diamonds/bluediamond.png',
            colorName: 'Blue'
        },
        {
            src: './assets/images/Diamonds/pinkdiamond.png',
            colorName: 'Pink'
        },
        {
            src: './assets/images/Diamonds/yellowdiamond.png',
            colorName: 'Yellow'
        }
    ]

    const [basketActiveIndex, setBasketActiveIndex] = useState(0)
    const [ringActiveIndex, setRingActiveIndex] = useState(0)
    const [caratActiveIndex,setCaratActiveIndex] = useState(2)
    const [metalActiveIndex, setMetalActiveIndex] = useState(1)
    const [diamondActiveIndex, setDiamondActiveIndex] = useState(0)
    const [gemstoneActiveIndex, setGemstoneActiveIndex] = useState(0)
    return <AppContext.Provider value={{
        basketActiveIndex, setBasketActiveIndex,
        ringActiveIndex, setRingActiveIndex,
        caratActiveIndex,setCaratActiveIndex,
        metalActiveIndex, setMetalActiveIndex,
        diamondActiveIndex, setDiamondActiveIndex,
        gemstoneActiveIndex, setGemstoneActiveIndex,
        metal,carat,crown,rings,
        label,gemstone,diamond
    }}>
        {children}
    </AppContext.Provider>
}

export { AppContext, AppContextProvider };