import React, { useContext, useState } from 'react'
import { ThreeDViewer } from '../Services/Viewer'
import { AppContext } from '../context/AppContextProvider';

const FirstStep = ({ currentStep, updateStep }) => {
    const{
        basketActiveIndex, setBasketActiveIndex,
        ringActiveIndex, setRingActiveIndex,
        caratActiveIndex,setCaratActiveIndex,
        metalActiveIndex, setMetalActiveIndex,
        metal,carat,crown,rings
    } = useContext(AppContext)
    const handleObjectVariationClick = (category, index, type, viewIndex) => {
        ThreeDViewer.applyObjectVariation(category, index, type);
        // viewAnimation(viewIndex)
    };

    const handleMaterialClick = (category, index, viewIndex) => {
        ThreeDViewer.applyMaterialVariation(category, index);
        // viewAnimation(viewIndex)
    };

    return (
        <div className='h-[90%] flex flex-col gap-[20px] relative text-[14px] overflow-y-auto'>
            <div className='flex flex-col gap-[10px]'>
                <p className="font-medium">Metal</p>
                <div className="flex gap-[30px]">
                    {
                        metal.map((value, index) => {
                            return <div id="view3" key={index} className='flex flex-col gap-[5px] items-center'>
                                <div className={`w-[50px] h-[50px] rounded-full cursor-pointer border-[2px] ${index != metalActiveIndex ? 'border-[rgb(211,211,211)]' : 'border-[rgb(0,0,0)]'}`}>
                                    <img src={value.src}
                                        className={`w-full h-full object-cover`}
                                        onClick={() => {
                                            setMetalActiveIndex(index)
                                            handleMaterialClick("Metal", index, 3)
                                        }} />
                                </div>

                                <p>{value.name}</p>
                            </div>
                        })
                    }
                </div>
            </div>
            <div className='flex flex-col gap-[10px]'>
                <p className="font-medium">Band</p>
                <div className="flex gap-[30px]">
                    {
                        rings.map((value, index) => {
                            return <div id="view3" key={index} className='flex flex-col gap-[5px] items-center'>
                                <div className={`w-[50px] h-[50px] rounded-full cursor-pointer border-[2px] ${index != ringActiveIndex ? 'border-[rgb(211,211,211)]' : 'border-[rgb(0,0,0)]'}`}>
                                    <img src={value.src}
                                        className={`w-full h-full object-cover`}
                                        onClick={() => {
                                            setRingActiveIndex(index)
                                            handleObjectVariationClick("Ring", index, "objects", 0)
                                        }} />
                                </div>

                                <p>{value.name}</p>
                            </div>
                        })
                    }
                </div>
            </div>
            {/* <div className='flex flex-col gap-[10px]'>
                <p className="font-medium">Carat</p>
                <div className="flex gap-[30px]">
                    {
                        carat.map((value, index) => {
                            return <div id="view3" key={index} className='flex flex-col gap-[5px] items-center overflow-hidden'>
                                <div className={`flex flex-col h-[50px] w-[50px] rounded-full justify-evenly cursor-pointer border-[2px] ${index == caratActiveIndex ? ' border-[black]' : 'border-[rgb(211,211,211)]'}`}
                                    onClick={() => {
                                        setCaratActiveIndex(index)
                                    }}
                                >
                                    <img src={value.src}
                                        className={`object-cover`} />

                                </div>

                                <p>{value.name}</p>
                            </div>
                        })
                    }
                </div>
            </div> */}
            <div className='flex flex-col gap-[10px]'>
                <p className="font-medium">Crown</p>
                <div className="flex gap-[30px]">
                    {
                        crown.map((value, index) => {
                            return <div id="view3" key={index} className='flex flex-col gap-[5px] items-center overflow-hidden'>
                                <div className={`flex flex-col h-[60px] w-[60px] justify-evenly cursor-pointer border-[2px] rounded ${index == basketActiveIndex ? ' border-[black]' : 'border-[rgb(211,211,211)]'}`}
                                    onClick={() => {
                                        setBasketActiveIndex(index)
                                        handleObjectVariationClick("Basket", index, "objects", 1)
                                    }}
                                >
                                    <img src={value.src}
                                        className={`object-cover h-[90%] w-[90%]`} />

                                </div>

                                <p>{value.name}</p>
                            </div>
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default FirstStep