import React, { useContext } from 'react'
import { AppContext } from '../context/AppContextProvider'

const ThirdStep = () => {
  const {metal,carat,crown,rings,
    basketActiveIndex, setBasketActiveIndex,
    ringActiveIndex, setRingActiveIndex,
    caratActiveIndex,setCaratActiveIndex,
    metalActiveIndex, setMetalActiveIndex,
    diamondActiveIndex, setDiamondActiveIndex,
    gemstoneActiveIndex, setGemstoneActiveIndex,
    label,gemstone,diamond
  } = useContext(AppContext)
  return (
    <div className='mt-[73px]'>
      <div className='font-bold text-[20px]'>Specifications</div>
      <div className='flex flex-col gap-[10px] mt-[10px]'>
        <div className='flex flex-col gap-[10px]'>
          <div className=' flex text-[16px]'>
            <p className='font-medium '>Metal: </p>
            <p>{metal[metalActiveIndex].name}</p>
          </div>
          <div className=' flex text-[16px]'>
            <p className='font-medium '>Band: </p>
            <p>{rings[ringActiveIndex].name}</p>
          </div>
          <div className=' flex text-[16px]'>
            <p className='font-medium '>Crown: </p>
            <p>{crown[basketActiveIndex].name}</p>
          </div>
          <div className=' flex text-[16px]'>
            <p className='font-medium '>Center Stone color: </p>
            <p>{gemstone[diamondActiveIndex].colorName}</p>
          </div>
        </div>
        <div>
          Actual presentation might appear slightly different from preview.
          Our experts will ensure the proper size and placement of your message.
          Items engraved with personalized information cannot be returned or exchanged.
          Inappropriate or profane language may result in the cancellation of your order.
        </div>
      </div>
    </div>
  )
}

export default ThirdStep