import React, { useContext, useState } from 'react'
import { ThreeDViewer } from '../Services/Viewer'
import { AppContext } from '../context/AppContextProvider'

const SecondStep = () => {
    const [currentTab, setCurrentTab] = useState(1)
    const { diamondActiveIndex, setDiamondActiveIndex,
        gemstoneActiveIndex, setGemstoneActiveIndex,
        label,gemstone,diamond
    }
        =useContext(AppContext)
    const [type, setType] = useState(0)
    
    const handleMaterialClick = (category, index, viewIndex) => {
        ThreeDViewer.applyMaterialVariation(category, index);
        // viewAnimation(viewIndex)
    };
    return (
        <div className='flex flex-col gap-[30px]'>
            {/* <div className='w-full flex text-center rounded-[5px]'>
                <div className={`py-[4px] rounded-l-[5px] w-[50%]`}
                    onClick={() => setCurrentTab(1)}
                >Center Stone</div>
            </div> */}
            <div className='flex flex-col gap-[10px]'>
                {/* <div>
                    <p className="font-medium">Center Stone</p>
                    <div className="flex gap-[30px]">
                        {
                            label.map((value, index) => {
                                return <div id="view3" key={index} className='text-[14px] flex flex-col gap-[5px] items-center'>
                                    <div className={` w-[60px] h-[60px] rounded-full cursor-pointer p-[0.3px] `}>
                                        <img src={value.src}
                                            className={`w-[60px] aspect-square rounded-full`}
                                            onClick={() => {
                                                setType(index)
                                            }} />
                                    </div>
                                    <p>{value.colorName}</p>
                                </div>
                            })
                        }
                    </div>
                </div> */}
                {
                    type == 0 ?
                        <div className='flex flex-col gap-[10px]'>
                            <p className="font-medium">Center Stone Color</p>
                            <div className="flex gap-[30px]">
                                {
                                    gemstone.map((value, index) => {
                                        return <div id="view3" key={index} className='text-[14px] flex flex-col gap-[5px] items-center'>
                                            <div className={` w-[60px] h-[60px] rounded-full cursor-pointer p-[0.3px] ${index == diamondActiveIndex ? 'border-[2px]  border-black' : 'border-[rgb(211,211,211)]'}`}>
                                                <img src={value.src}
                                                    className={`w-[60px] aspect-square rounded-full`}
                                                    onClick={() => {
                                                        setDiamondActiveIndex(index)
                                                        handleMaterialClick("mainDiamond", index, 3)
                                                    }} />
                                            </div>
                                            <p>{value.colorName}</p>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                        :
                        <div className='flex flex-col gap-[10px]'>
                            <p className="font-medium">Color</p>
                            <div className="flex gap-[30px]">
                                {
                                    diamond.map((value, index) => {
                                        return <div id="view3" key={index} className='text-[14px] flex flex-col gap-[5px] items-center'>
                                            <div className={` w-[60px] h-[60px] rounded-full cursor-pointer p-[0.3px] ${index == gemstoneActiveIndex ? 'border-[2px]  border-black' : 'border-[rgb(211,211,211)]'}`}>
                                                <img src={value.src}
                                                    className={`w-[60px] aspect-square rounded-full`}
                                                    onClick={() => {
                                                        setGemstoneActiveIndex(index)
                                                        // handleMaterialClick("mainDiamond", index, 3)
                                                    }} />
                                            </div>
                                            <p>{value.colorName}</p>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                }


            </div>
        </div>
    )
}

export default SecondStep