import React, { useEffect } from 'react'
import { ThreeDViewer } from '../Services/Viewer'

const FourthStep = () => {
    // useEffect(()=>{
    //         ThreeDViewer.setRingOnModelHand('webgi-canvas')
    // },[])
  return (
    <div className='flex flex-col gap-[30px] mt-[73px]'> 
        <div className='w-[180px] h-[180px] border'>
          <img src="./assets/images/ring.avif" alt="" className='w-full h-full' style={{transform:"rotate(120deg)"}}/>
            {/* <canvas id='ring-canvas' className='w-[150px] h-[150px]'></canvas> */}
        </div>
        <div>
        <div className='font-medium'>Specifications</div>
        <div className='flex flex-col gap-[10px] text-[14px] mt-[5px]'>
          <p>White Gold Ring - 22K</p>
          <p>Pearl Halo-cut 10K</p>
          <p>Alliance</p>
        </div>
        </div>
    </div>
  )
}

export default FourthStep